$primary: #66E4EE;
$secondary: #004666;
$secondary-light: #006384;
$light-grey: #F2F2F2;
$medium-grey: #D8D8D8;
$grey: #A8ADB3;
$white: #fff;
$yellow: #F5E850;
$danger: #CD3B4F;
$success: #05CE9F;
$text-grey: #4C5356;

$xxl: 1920px;
$xl: 1600px;
$l: 1280px;
$m: 960px;
$s: 720px;
$xs: 480px;