@import "./variables.scss";
@import "~nouislider/distribute/nouislider.min.css";
@import '~swiper/swiper-bundle';
@import "./assets/styles/modals";

app-full-layout {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.grecaptcha-badge {
    visibility: hidden;
}

.c-jump-nav {
    &-bar{
        background: $secondary-light;

        & .o-page-wrap {
            padding-left: 25px;

            & .js-jump-nav__item a{
                color: $white;
                padding: 7px 20px;
                margin-right: 0;
                font-weight: 100;

                @media (max-width: $m) {
                    padding: 7px 10px;
                }

                &.is-active {
                    background: rgba($primary, 50%);
                }

                &::after{
                    content: none;
                }
            }
        }
    }

    &-wrapper {
        margin: 0;
        overflow-x: hidden;
    }
}

.header-navigation {
    background: $white;
    color: $grey;
    border-bottom: 1px solid $medium-grey;
   // padding-top: 14px;
   // padding-bottom: 14px;
    z-index: 1300;
    position: relative;

    &-blue {
        background: $secondary-light;
    }

    & > .o-page-wrap {
        position: relative;

        & > * {
            padding: 14px 0;
        }
    }

    &-bg-light {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 50%;
        left: 0;
        background: $light-grey;

        @media (max-width: $m) {
            display: none;
        }
    }

    .o-page-wrap {
        display: flex;
        justify-content: space-between;

        & .legend {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $text-grey;
            font-weight: 700;
        }

        & .search-bar{
            display: flex;
            flex-basis: 88%;
            justify-content: space-between;
            background: $white;
            padding-left: 15px;
            //background: $secondary-light;

            @media (max-width: $m) {
                flex-basis: 100%;
            }

            & .buttons {
                display: flex;
                position: relative;

                & .c-btn--secondary {
                    background: $white;
                    border: 1px solid $secondary;
                }

                & .c-btn-search {
                    @media (max-width: $m) {
                        padding: 1px 6px;
                    }
                    i {
                        display: none;
                        @media (max-width: $m) {
                            display: block;
                        }
                    }
                }
            }

            & .c-input {
                width: 100%;
                border: none;

                &::before {
                    content: "";
                    width: 1px;
                    border: 1px solid $grey;
                }

                & .c-input__input{
                //    background-color: $secondary-light;
                    border: 0;
                    /*
                    &::placeholder{
                        color: $white;
                    }*/
                    &:focus {
                        border: 0;
                    }


                }
            }
        }
    }

    .c-breadcrumb__link, .c-breadcrumb__item:not(:last-child)::after {
        color: $white;
    }

    .c-breadcrumb__item:first-child {
        & .c-breadcrumb__link::before {
            display: none;
        }
        & .c-breadcrumb__label {
            position: relative !important;
            width: auto;
            height: auto;
            overflow: unset;
            clip: unset;
        }
    }
}

.o-content-section {
    min-height: 40vh;
    padding-top: 30px;
    padding-bottom: 30px;

    @media(max-width: $s) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.o-root {
    overflow-x: hidden;
}

.c-btn.c-btn--yellow {
    border: 1px solid $yellow;
    background-color: $yellow;
    color: $secondary;
}

.c-btn.c-btn--danger {
    border: 1px solid $danger;
    background-color: $danger;
    color: $white;
}

.u-text-success {
    color: $success !important;
}

.u-text-danger {
    color: $danger !important;
}

.u-border-success {
    border-color: $success !important;
}

.u-border-danger {
    border-color: $danger !important;
}

.u-border-yellow {
    border-color: $yellow !important;
}

.u-border-primary {
    border-color: $primary !important;
}

.u-border-light {
    border-width: 1px !important;
}

.c-modal__{
    &header {
        flex-direction: row-reverse;
    }

    &title {
        flex-grow: 0;
        flex-basis: auto;

        &__wrapper {
            margin-top: 0;
            flex-shrink: 0;
            flex-basis: auto;
        }
    }

    &content {
        text-align: left;
        max-height: unset;

        & .c-card__badge {
            justify-content: flex-start;
        }
    }

    &footer::before {
        background: none;
    }

    &actions {
        justify-content: flex-start;

        &__item {
            flex-basis: auto;
        }
    }
}

.u-bg-light-blue {
    background-color: rgb(102, 228, 238, 0.1);
}

.u-text-primary {
    color: $primary;
}

table {
    thead th {
        font-weight: 100;
        color: $grey !important;

        &::before {
            border-top: 0 !important;
        }
    }
}

.js-jump-nav {
    &__link:first-letter {
        text-transform: uppercase;
    }
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.justify-content-end {
    justify-content: flex-end;
}

.items-center {
    align-items: center;
}

.d-sm-flex {
    @media (max-width: $s) {
        display: flex;
    }
}

.flex-column {
    flex-direction: column;
}

.flex-sm-column {
    @media (max-width: $s) {
        flex-direction: column;
    }
}

.justify-content-center {
    justify-content: center;
}
.justify-content-between {
    justify-content: space-between;
}

.noUi{
    &-horizontal {
        height: 5px;
        border: 0;
    }

    &-connects {
        background: $secondary; //$light-grey;
    }

    &-connect {
        background: $grey;
    }

    &-handle {
        border-radius: 20px;
        border: 1px solid $primary;

        &:focus {
            outline: none;
        }
    }

    &-horizontal .noUi-handle {
        top: -16px;
        width: 34px;
        height: 34px;
    }

    &-handle:after{
        content: none;
    }

    &-handle:before {
        height: 10px;
        width: 10px;
        background: $secondary;
        left: 11px;
        top: 12px;
        border-radius: 10px;
    }
}

.color-primary {
    color: $primary !important;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $secondary-light $light-grey;
}

  /* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: rgba($light-grey, 0.5);
}

*::-webkit-scrollbar-thumb {
    background-color: $secondary-light;
    border-radius: 20px;
 //   border: 3px solid $light-grey;
}

body{
    .auctions-navigation-bar {
        & .notification-bell , & .c-notification-center__panel {
            display: none;
        }
    }

    &.no-scroll {
        overflow-y: hidden;
    }
    &.navbar-scrolled {
        .auctions-navigation-bar {
            & .notification-bell , & .c-notification-center__panel {
                display: block;
            }
        }

        header .c-notification-center__panel {
            display: none;
        }

        & .c-jump-nav-bar {
            z-index: 1301;
            position: fixed;
            animation: modal-filters-enter-top 1s forwards;
            -webkit-animation: modal-filters-enter-top 1s forwards;
        }

        main {
            margin-top: 126px;
        }
    }
}

@keyframes modal-filters-enter-top {
    from {transform: translateY(-58px);}
    to {transform: none;}
}

h1 {
    font-size: 80px;
}

h2 {
    font-size: 48px;
}

h3 {
    font-size: 36px;

    @media (max-width: $m) {
        font-size: 25px;
    }
}

h4 {
    font-size: 30px;
    color: $text-grey;
}

h5 {
    font-size: 22px;
}

h6 {
    font-size: 20px;
}

p, dt {
    font-size: 15px;

    @media (max-width: $m) {
        font-size: 13px;
        line-height: 1;
    }
}

small {
    line-height: 19px;

    @media (max-width: $m) {
        font-size: 11px;
        line-height: 1;
    }
}

label, .c-form-field__label, .c-checkbox__label, .c-radio__label {
    font-size: 15px;
    @media (max-width: $m) {
        font-size: 13px;
    }
}

.c-checkbox {
    position: relative;
}

.c-form-field__label {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
    color: $text-grey;

    &.intro {
        color: $secondary;
    }
}

.c-radio__label, .c-checkbox__label {
    font-weight: 700;
    color: $text-grey;
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, blockquote, p, address, hr, table, pre {
    margin-bottom: 14px;

    @media (max-width: $m) {
        margin-bottom: 8px;
    }
}

.c-icon-notify {
    border: 3px solid;
    padding: 10px;
    border-radius: 100%;

    &.success {
        border-color: $success;
    }

    &.error {
        border-color: $danger;
    }
}

.c-table-wrapper {
    & .c-icon.c-icon.c-icon:not(.c-info-icon) {
        font-size: 1.5rem;
        cursor: pointer;
    }
    & .action {
        width: 80px;
    }
}

.c-modal__wrapper {
    width: 60vw;
    max-width: 60vw;

    @media (max-width: $m) {
        width: calc(100vw - 56px);
        max-width: calc(100vw - 56px);

        .c-modal__title__wrapper {
            width: 100%;

            .c-modal__title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;

                @media (max-width: $s) {
                    width: 90%;
                }
            }
        }
    }
}

.c-tag {
    border: 1px solid $light-grey;
    background-color: $light-grey;
    font-size: 12px;

    @media (max-width: $s) {
        padding: 3px 5px;
    }

    &--info {
        border: 1px solid $secondary;
        background-color: $secondary;
    }

    &--white {
        border: 1px solid $light-grey;
        background-color: $white;
    }

    &-list li {
        margin-top: 0;
    }
}

.text-decoration-none {
    text-decoration: none;
}

.u-border-small {
    border: 1px solid $medium-grey;
}

fieldset:disabled {
    .c-input__input {
        border-color: $medium-grey;
        background-color: $light-grey;
        color: $grey;
        pointer-events: none;
        cursor: default;
        -webkit-text-fill-color: $grey;
        opacity: 1;
    }
}

.c-tag--border-info {
    border: 1px solid $secondary;
}

.c-notification-top {
    top: 10%;
    left: 95%;
}

.w-50 {
    width: 50%;
}

.w-75 {
    width: 75%;
}

.w-100 {
    width: 100%;
}

.c-notification-center__item b {
    font-weight: 700;
}

@media (max-width: $s) {
    h4 {
        font-size: 20px;
    }
    .c-breadcrumb__item:not(:last-child)::after {
        margin-left: 2px;
    }

    .c-breadcrumb__item:not(:last-child) {
        margin-right: 2px;
    }

    .c-jump-nav-bar .o-page-wrap {
        padding-left: 12px;
    }
}

.tooltip {
    z-index: 9999;
    visibility: hidden;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translateY(20px);
    left: -40px;
    width: calc(100% + 80px);


    & .tippy-content {
        text-transform: none;
    }

    & .tippy-arrow {
        position: absolute;
        left: calc(50% - 10px);
    }
}

input#page-search-bar::-webkit-outer-spin-button,
input#page-search-bar::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input#page-search-bar[type=number] {
  -moz-appearance: textfield;
}



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;

    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    border: 1px solid $secondary;
    -webkit-transition: .4s;
    transition: .4s;

    &::before{
        position: absolute;
        content: "";
        height: 23px;
        width: 23px;
        left: 4px;
        bottom: 3px;
        background-color: $secondary;
        -webkit-transition: .4s;
        transition: .4s;
    }
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.cursor-pointer {
    cursor: pointer;
}

.u-border-none {
    border: none;
}

.u-bg--transparent {
    background-color: transparent !important;
}

.header-navigation .search-bar {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
